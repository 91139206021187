import React from 'react';
import ReactDOM from 'react-dom/client';
import './Resources/css/app.css';
import { RouterProvider } from 'react-router-dom';
import Router from './Routers/Router';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={Router} />
    </React.StrictMode>
);

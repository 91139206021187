import { createBrowserRouter } from "react-router-dom";
import Main from "../Pages/Main";
import Video from "../Pages/Video";

export default createBrowserRouter([
    {
        path: "/",
        element: <Main />,
    },
    {
        path: "/category/:categoryId",
        element: <Main />,
    },
    {
        path: "/video",
        element: <Video />,
    },
]);
import { memo, useCallback, useEffect, useState } from "react";
import { AppVideoCategoryApiResponse, VideoCategory } from "../types";
import { DEFAULT_REQUEST_ARGS } from "../config";
import * as he from "he";
import CategorySkeleton from "./CategorySkeleton";
import api from "../Services/api";

interface CategoriesProps {
    parent?: VideoCategory;
    onSelectCategory: (category?: VideoCategory) => void;
}

const Categories = ({ parent, onSelectCategory }: CategoriesProps) => {
    const [videoCategories, setVideoCategories] = useState<VideoCategory[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const loadVideoCategories = useCallback(async (categoryId: number) => {
        setIsLoading(true);
        try {
            const categoryResponseParams = new URLSearchParams({
                ...DEFAULT_REQUEST_ARGS,
                parent: categoryId.toString(),
            });

            const categoryResponse = await api.get<AppVideoCategoryApiResponse[]>('/app-video-category', { params: categoryResponseParams });
            const categories: VideoCategory[] = [];

            categoryResponse.data.forEach((category) => {
                categories.push({
                    id: category.id,
                    name: he.decode(category.name),
                    parent: category.parent,
                    order: typeof category.acf.order_number === 'number' ? category.acf.order_number : parseInt(category.acf.order_number),
                });
            });

            categories.sort((a, b) => (a.order > b.order) ? 1 : -1);

            setVideoCategories(categories);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        loadVideoCategories(parent?.id || 0);
    }, [parent, loadVideoCategories]);

    if (isLoading) {
        return (
            <div className="grid md:grid-cols-2 gap-4">
                <CategorySkeleton />
                <CategorySkeleton />
            </div >
        )
    }

    if (videoCategories.length === 0) {
        return null;
    }

    return (
        <div className="grid md:grid-cols-2 gap-4">
            {videoCategories.map((category) => (
                <button
                    key={category.id}
                    className="relative text-left group flex items-center overflow-hidden border border-slate-400 rounded py-3 cursor-pointer" onClick={() => onSelectCategory(category)}
                    type="button"
                >
                    <div className="absolute w-0 bg-blue-100 transition-all duration-300 top-0 left-0 bottom-0 group-hover:w-full overflow-hidden" />
                    <span className="mx-4 text-base text-slate-800 z-10">{category.name}&nbsp;&raquo;</span>
                </button>
            ))}
        </div>
    )
};

export default memo(Categories);
import { useEffect, useState } from 'react';
import Layout from '../Layouts/Layout';
import Categories from '../Components/Categories';
import Videos from '../Components/Videos';
import { Video, VideoCategory } from '../types';
import Header from '../Components/Header';
import { useLocation, useNavigate } from 'react-router-dom';

const Main = () => {
    const [category, setCategory] = useState<VideoCategory>();
    const [videos, setVideos] = useState<Video[]>([]);

    const navigate = useNavigate();

    const onSelectCategory = (category?: VideoCategory) => {
        if (category) {
            navigate(`/category/${category.id}`, { state: { category } });
        } else {
            navigate('/');
        }
    }

    const { state } = useLocation();

    const goBack = () => {
        setVideos([]);
        navigate(-1);
    }

    useEffect(() => {
        if (state) {
            setCategory(state.category);
        } else {
            setCategory(undefined);
        }
    }, [state]);

    return (
        <Layout header={<Header />}>
            <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-1">
                    <h2 className="text-xl small-caps text-center">
                        {category ? `Category: ${category.name}` : 'Categories'}
                    </h2>
                    {videos.length > 0 && (
                        <span className="text-sm text-slate-500 text-center">{videos.length} videos</span>
                    )}
                    {category && (
                        <div className="flex items-center justify-center gap-4 mt-3">
                            <button className="small-caps text-sm hover:text-cyan-700 transition-colors"
                                onClick={goBack}>
                                [ go back ]
                            </button>
                        </div>
                    )}
                </div>
                <Categories parent={category} onSelectCategory={(onSelectCategory)} />
                <Videos categoryId={category?.id || 0} onVideosLoaded={(videos) => setVideos(videos)} />
            </div>
        </Layout>
    )
};

export default Main;
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../Layouts/Layout";
import { AppVideo } from "../types";
import Header from "../Components/Header";

const Video = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { video }: { video: AppVideo, categoryId: number } = state;

    return (
        <Layout header={<Header />}>
            <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-4">
                    <h2 className="text-xl small-caps text-center">
                        Video: {video.title}
                    </h2>
                    <div className="flex items-center justify-center gap-4">
                        <button className="small-caps text-sm hover:text-cyan-700 transition-colors"
                            onClick={() => navigate(-1)}>
                            [ go back ]
                        </button>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <video controls autoPlay className="aspect-video bg-slate-300 rounded">
                        <source src={video.url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="flex items-center justify-center gap-4">
                        <div className="text-base text-slate-800">{video.description}</div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Video;